import { useState, useCallback, useRef } from 'react';
// state machine to represent the steps taken to assign a column to target field:
// - pick column (drag start or keyboard select)
// - hover over field (while dragging only)
// - assign picked column to field (drag end)
// @todo move the useDrag setup outside as well?
export function useColumnDragState(onColumnAssignment) {
    // wrap in ref to avoid re-triggering effects
    const onColumnAssignmentRef = useRef(onColumnAssignment);
    onColumnAssignmentRef.current = onColumnAssignment;
    const [dragState, setDragState] = useState(null);
    const dragStartHandler = useCallback((column, startFieldName, initialClientRect) => {
        // create new pointer-based drag state
        setDragState({
            pointerStartInfo: {
                initialClientRect
            },
            column,
            dropFieldName: startFieldName !== undefined ? startFieldName : null,
            updateListeners: []
        });
    }, []);
    const dragMoveHandler = useCallback((movement) => {
        // @todo figure out a cleaner event stream solution
        if (dragState) {
            const listeners = dragState.updateListeners;
            for (const listener of listeners) {
                listener(movement);
            }
        }
    }, [dragState]);
    const dragEndHandler = useCallback(() => {
        setDragState(null);
        if (dragState) {
            onColumnAssignmentRef.current(dragState.column, dragState.dropFieldName);
        }
    }, [dragState]);
    const columnSelectHandler = useCallback((column) => {
        setDragState((prev) => {
            // toggle off if needed
            if (prev && prev.column === column) {
                return null;
            }
            return {
                pointerStartInfo: null,
                column,
                dropFieldName: null,
                updateListeners: []
            };
        });
    }, []);
    const dragHoverHandler = useCallback((fieldName, isOn) => {
        setDragState((prev) => {
            if (!prev) {
                return prev;
            }
            if (isOn) {
                // set the new drop target
                return Object.assign(Object.assign({}, prev), { dropFieldName: fieldName });
            }
            else if (prev.dropFieldName === fieldName) {
                // clear drop target if we are still the current one
                return Object.assign(Object.assign({}, prev), { dropFieldName: null });
            }
            // no changes by default
            return prev;
        });
    }, []);
    const assignHandler = useCallback((fieldName) => {
        // clear active drag state
        setDragState(null);
        if (dragState) {
            onColumnAssignmentRef.current(dragState.column, fieldName);
        }
    }, [dragState]);
    const unassignHandler = useCallback((column) => {
        // clear active drag state
        setDragState(null);
        onColumnAssignmentRef.current(column, null);
    }, []);
    return {
        dragState,
        dragStartHandler,
        dragMoveHandler,
        dragEndHandler,
        dragHoverHandler,
        columnSelectHandler,
        assignHandler,
        unassignHandler
    };
}
