import React, { useState, useMemo } from 'react';
import { ColumnDragCard } from './ColumnDragCard';
import { IconButton } from '../IconButton';
import './ColumnDragSourceArea.scss';
import { useLocale } from '../../locale/LocaleContext';
const DEFAULT_PAGE_SIZE = 5; // fraction of 10 for easier counting
// @todo readable status text if not mouse-drag
const SourceBox = ({ column, fieldAssignments, dragState, eventBinder, onSelect, onUnassign }) => {
    const isDragged = dragState ? column === dragState.column : false;
    const isAssigned = useMemo(() => Object.keys(fieldAssignments).some((fieldName) => fieldAssignments[fieldName] === column.index), [fieldAssignments, column]);
    const eventHandlers = useMemo(() => eventBinder(column), [
        eventBinder,
        column
    ]);
    const l10n = useLocale('fieldsStep');
    return (React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__box" },
        React.createElement("div", Object.assign({}, (isAssigned ? {} : eventHandlers), { style: { touchAction: 'none' } }),
            React.createElement(ColumnDragCard, { column: column, isAssigned: isAssigned, isShadow: isDragged || isAssigned, isDraggable: !dragState && !isDragged && !isAssigned })),
        React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__boxAction" }, isAssigned ? (React.createElement(IconButton, { key: "clear" // key-prop helps clear focus on click
            , label: l10n.clearAssignmentTooltip, small: true, type: "replay", onClick: () => {
                onUnassign(column);
            } })) : (React.createElement(IconButton, { key: "dragSelect" // key-prop helps clear focus on click
            , focusOnly: true, label: dragState && dragState.column === column
                ? l10n.unselectColumnTooltip
                : l10n.selectColumnTooltip, small: true, type: "back", onClick: () => {
                onSelect(column);
            } })))));
};
// @todo current page indicator (dots)
export const ColumnDragSourceArea = ({ columns, columnPageSize, fieldAssignments, dragState, eventBinder, onSelect, onUnassign }) => {
    // sanitize page size setting
    const pageSize = Math.round(Math.max(1, columnPageSize !== null && columnPageSize !== void 0 ? columnPageSize : DEFAULT_PAGE_SIZE));
    // track pagination state (resilient to page size changes)
    const [pageStart, setPageStart] = useState(0);
    const [pageChanged, setPageChanged] = useState(false);
    const page = Math.floor(pageStart / pageSize); // round down in case page size changes
    const pageCount = Math.ceil(columns.length / pageSize);
    // display page items and fill up with dummy divs up to pageSize
    const pageContents = columns
        .slice(page * pageSize, (page + 1) * pageSize)
        .map((column, columnIndex) => (React.createElement(SourceBox, { key: columnIndex, column: column, fieldAssignments: fieldAssignments, dragState: dragState, eventBinder: eventBinder, onSelect: onSelect, onUnassign: onUnassign })));
    while (pageContents.length < pageSize) {
        pageContents.push(React.createElement("div", { key: pageContents.length, className: "CSVImporter_ColumnDragSourceArea__pageFiller" }));
    }
    const l10n = useLocale('fieldsStep');
    return (React.createElement("section", { className: "CSVImporter_ColumnDragSourceArea", "aria-label": l10n.dragSourceAreaCaption },
        React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__control" },
            React.createElement(IconButton, { label: l10n.previousColumnsTooltip, type: "back", disabled: page === 0, onClick: () => {
                    setPageStart((prev) => Math.max(0, Math.floor(prev / pageSize) - 1) * pageSize);
                    setPageChanged(true);
                } })),
        React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__page" },
            dragState && !dragState.pointerStartInfo ? (React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__pageIndicator", role: "status" }, l10n.getDragSourceActiveStatus(dragState.column.code))) : (
            // show page number if needed (and treat as status role if it has changed)
            // @todo changing role to status does not seem to work
            pageCount > 1 && (React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__pageIndicator", role: pageChanged ? 'status' : 'text' }, l10n.getDragSourcePageIndicator(page + 1, pageCount)))),
            pageContents),
        React.createElement("div", { className: "CSVImporter_ColumnDragSourceArea__control" },
            React.createElement(IconButton, { label: l10n.nextColumnsTooltip, type: "forward", disabled: page >= pageCount - 1, onClick: () => {
                    setPageStart((prev) => Math.min(pageCount - 1, Math.floor(prev / pageSize) + 1) *
                        pageSize);
                } }))));
};
